<template>
  <div>
    <div class="opportunity-filter">
      <div class="item ddlValue">
        <b-navbar-nav class="m-auto">
          <b-nav-item-dropdown text="Imóvel">
            <b-dropdown-item v-for="item in listImmobiles" v-bind:key="item" @click="setImmobile(item)">
              {{ item }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
      <div class="item datepicker">
        <b-navbar-nav>
          <b-nav-item-dropdown id="dropdown-form" ref="dropdownDate" text="Data">
            <b-dropdown-form>
                <b-form-group b-form-group label="De" label-for="dropdown-form-de">
                  <b-form-input
                    v-model="filters.acquisitionStartFilter"
                    v-mask="'##/##/####'"
                    placeholder="De"/>
                </b-form-group>

                <b-form-group label="Até" label-for="dropdown-form-ate">
                  <b-form-input
                    v-model="filters.acquisitionFinishFilter"
                    v-mask="'##/##/####'"
                    placeholder="Até"/>
                </b-form-group>

                <b-button variant="secondary" size="sm" @click="cancelDate()">CANCELAR</b-button>
                <b-button variant="primary" size="sm" @click="getInvestments()">OK</b-button>
            </b-dropdown-form>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
      <div class="item ddlValue">
        <b-navbar-nav class="m-auto">
          <b-nav-item-dropdown ref="dropdownValue" text="Valor">
            <b-dropdown-form>
              <b-form-group label="Até" label-for="dropdown-form-end">
                <b-input-group label="De" prepend="R$" class="dropdown-form-end">
                  <b-form-input id="inline-form-input-ofValue" placeholder="0000,00" v-model="filters.minQuotaValueFilter"></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-form-group label="Até" label-for="dropdown-form-end">
                <b-input-group label="De" prepend="R$" class="dropdown-form-end">
                  <b-form-input id="inline-form-input-untilValue" placeholder="0000,00" v-model="filters.maxQuotaValueFilter"></b-form-input>
                </b-input-group>
              </b-form-group>

              <b-button variant="secondary" size="sm" @click="clearValueFiler()">CANCELAR</b-button>
              <b-button variant="primary" size="sm" @click="getInvestments()">OK</b-button>
            </b-dropdown-form>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
    <vue-good-table
      id="table_investments"
      :columns="columns"
      :rows="investments"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: search,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'immobileName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.immobileName }}</span>
        </span>

        <span v-else-if="props.column.field === 'qtdAvailable'" class="text-nowrap">
          <feather-icon icon="PieChartIcon" class="mr-50" />
          <span class="text-nowrap">{{ props.row.qtdAvailable }}</span>
        </span>

        <span v-else-if="props.column.field === 'total'" class="text-nowrap">
          <feather-icon icon="DollarSignIcon" class="mr-50" />
          <span class="text-nowrap">{{ formatCurrencyPrice(props.row.total) }}</span>
        </span>

        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
          <feather-icon icon="CalendarIcon" class="mr-50" />
          <span class="text-nowrap">{{ props.row.date }}</span>
        </span>

        <span v-else-if="props.column.field === 'situation' && pending === true" class="text-nowrap">
          <b-badge>
              {{ statusInvestmentVariantName(props.row.situation) }}
            </b-badge>
        </span>

        <span v-else-if="props.column.field === 'percentCaptured' && inProgress === true" class="text-nowrap">
          <b-badge :variant="percentVariant(props.row.percentCaptured > 50 ? 1 : 2 )">
              {{ props.row.percentCaptured }} % Captado
            </b-badge>
        </span>

        <span v-else-if="props.column.field === 'situation' && (sold === true || closed === true)" class="text-nowrap">
          <b-badge :variant="percentVariant(props.row.situation)">
              {{ statusInvestmentVariantName(props.row.situation) }}
            </b-badge>
        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link v-if="pending" title="Visualizar detalhes" @click="goToCheckout(props.row.investmentId, props.row.immobileId)" >
            <feather-icon icon="EyeIcon" class="mr-50" />
          </b-link>

          <b-link v-else title="Visualizar detalhes" :to="{ name: 'user-project-detail', params: { id: props.row.investmentId, nameProfile: 'investor', path: `/investments/project-detail/${props.row.investmentId}/investor` }, }">
            <feather-icon icon="EyeIcon" class="mr-50" />
          </b-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ totalCount }} Resultados </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownForm, BInputGroupText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BIcon, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'
import BackgroundCard from '@/views/template/BackgroundCard.vue'
import InvestmentService from '@/services/Investment/InvestmentService'
import { VueGoodTable } from 'vue-good-table'
import { codeBasic } from '@/views/codeTable'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import formatter from '@/mixins/formatter'
import SearchBarVue from '@/layouts/navbar/components/SearchBar.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import InvestmentSituationEnum from '@/enums/InvestmentSituationEnum'
import ImmobileStatusEnum from '@/enums/ImmobileStatusEnum'

export default {
  components: {
    flatPickr,
    BInputGroupText, 
    BInputGroupAppend,
    BInputGroup,
    BPagination,
    BackgroundCard,
    BFormSelect,
    BCard,
    BCollapse,
    BButton,
    BLink,
    BBadge,
    VueGoodTable,
    BIcon,
    SearchBarVue, 
    BFormGroup,
    BRow, 
    BCol,
    BFormInput,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownForm
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  props: ['pending', 'cancelado', 'inProgress', 'closed', 'sold'],
  mixins:[formatter],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,    
    'b-toggle': VBToggle
  },
  data: () => ({
    investments: [],
    pageLength: 10,
    currentPage: 1,
    totalCount: 0,
    dir: false,
    codeBasic,
    filters: {},
    search: '',
    columns: [],
    config: {
      dateFormat: 'd/m/Y',
      locale: Portuguese,
    },
    listStatusCota: InvestmentSituationEnum.listStatusCota,
    listStatusComissao: InvestmentSituationEnum.listStatusComissao,
    listStatusCotaEnum: ['Pendente Pagamento', 'Pendente Assinatura', 'Pago', 'Cancelado'],
    listImmobiles: []
  }),
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
    percentVariant() {
      const statusColor = {
        1: 'light-success',
        2: 'light-primary',
        3: 'light-success'
      }

      return status => statusColor[status]
    },  
    
    statusInvestmentVariantName() {
      const statusName = {
        1: 'Pendente Pagamento',
        2: 'Pendente Assinatura',
        3: 'Pago',
        4: 'Cancelado'
      }
      return status => statusName[status]
    },
  },

  watch: {
    currentPage: {
      handler(val) {
        this.getInvestments()
      },
      immediate: true,
      }
  },

  methods: {
    async getInvestments(){
      let filters = this.getFilters()
      filters = `${filters}&SkipCount=${(10 * this.currentPage) - 10}`

      if (this.closed) {
        filters = `${filters}&immobileStatusFilter=${ImmobileStatusEnum.ENCERRADO}&situationFilter=3`
      } else if (this.sold) {
        filters = `${filters}&immobileStatusFilter=${ImmobileStatusEnum.VENDIDO}&situationFilter=3`
      } else if (this.inProgress) {
        filters = `${filters}&immobileStatusFilter=${ImmobileStatusEnum.ANDAMENTO}&situationFilter=3`
      }
  	  	let result = {}
      if(this.pending) { 
        result = (await InvestmentService.getAllByInvestorPending(filters)).data.result
      } else {        
        result = (await InvestmentService.getAllByInvestor(filters)).data.result
      }
      this.investments = result.items
      this.totalCount = result.totalCount  
    },

    async getImmobiles(){
      this.listImmobiles =  (await InvestmentService.getImmobilesByInvestor()).data.result
    },

    getFilters(){
      let filters = ``
      if(this.filters.minQuotaValueFilter !== undefined) {
        filters = `${filters}&minQuotaValueFilter=${this.filters.minQuotaValueFilter}`
      }
      if(this.filters.maxQuotaValueFilter !== undefined) {
        filters = `${filters}&maxQuotaValueFilter=${this.filters.maxQuotaValueFilter}`
      }
      if(this.filters.immobileNameFilter !== undefined) {
        filters = `${filters}&immobileNameFilter=${this.filters.immobileNameFilter}`
      }

      return filters
    },

    setImmobile(value){
      this.filters.immobileNameFilter = value
      this.getInvestments()
    },

    clearValueFiler(){
      this.filters.acquisitionStartFilter = undefined
      this.filters.acquisitionFinishFilter = undefined
      this.$refs.dropdownValue.hide()
      this.getInvestments()
    },

    cancelDate(){
      this.filters.minQuotaValueFilter = undefined
      this.filters.maxQuotaValueFilter = undefined
      this.$refs.dropdownDate.hide()
      this.getInvestments()
    },

    goToCheckout(investmentId, immobileId){
      localStorage.setItem('immobileIdSelected', immobileId)
      this.$router.push({ id: investmentId, path: '/checkout/'+investmentId })
    },

    clear(){
      this.filters = {}
      this.getInvestments()
    },


    getColumn() {
      this.columns = [
        {
          label: 'Imóvel',
          field: 'immobileName',
        },
        {
          label: 'Qtd Disponível',
          field: 'qtdAvailable',
        },
        {
          label: 'Total',
          field: 'total',
        },
        {
          label: 'Data',
          field: 'date',
        },
        {
          label: 'Status',
          field: 'situation',
        },
        {
          label: '',
          field: 'action',
        }
      ]
    },

    getColumnInProgress() {
      this.columns = [
        {
          label: 'Imóvel',
          field: 'immobileName',
        },
        {
          label: 'Qtd Disponível',
          field: 'qtdAvailable',
        },
        {
          label: 'Total',
          field: 'total',
        },
        {
          label: 'Data',
          field: 'date',
        },
        {
          label: 'Percentual Capturado',
          field: 'percentCaptured',
        },
        {
          label: '',
          field: 'action',
        }
      ]
    }
  },

  async mounted() {
    if (this.inProgress === true) {
      this.getColumnInProgress()
    } else {
      this.getColumn()
    }

    this.getInvestments()
    this.getImmobiles()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.icon-title {
  margin-top: 0.5%;
  margin-left: 1%
}

.text-center-position {
  margin-left: 45%;
}
body {
  background-color: #f8f8f8 !important;
}
#table_investments thead {
  display: none;
}

#table_investments tr {
  border: 0;
  display: block;
  margin: 15px 3px;
  border: 1px #fff solid;
  border-radius: 10px;
}

#table_investments td {
  padding: 5px;
}
#table_investments tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

#table_investments  .vgt-table.bordered td,  #table_investments .vgt-table.bordered th {
  border: #fff !important;
}

//#table_investments table.vgt-table td {
//  padding: 0.75em 0.75em 0.75em 0.75em !important;
//}

#table_investments .vgt-left-align {
  text-align: left !important;
}

#table_investments table.vgt-table td {
  vertical-align: baseline !important;
  color: #6e6b7b;
}

#table_investments th:first-child { min-width: 30px !important;  }
#table_investments th { min-width: 250px !important;  }
#table_investments th:last-child { min-width: 100px !important;  }

#table_investments table tbody td:nth-child(1)  { width: 20% !important;   }
#table_investments table tbody td:nth-child(2)  { width: 15% !important;   }
#table_investments table tbody td:nth-child(3)  { width: 15% !important;   }
#table_investments table tbody td:nth-child(4)  { width: 15% !important;   }
#table_investments table tbody td:nth-child(5)  { width: 15% !important;   }
#table_investments table tbody td:nth-child(6)  { width: 10% !important;   }
#table_investments table tbody td:nth-child(7)  { width: 10% !important;   }
#table_investments table tbody td:nth-child(8)  { width: 5% !important;   }

#table_investments table.vgt-table tbody {
  font-size: 13px;
}
#table_investments table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
}
#table_investments .vgt-table th.line-numbers, #table_investments .vgt-table th.vgt-checkbox-col {
  padding: 0 2.75em 0 0.75em;
}
#table_investments a { color: #828282; }
#table_investments a:hover { color: var(--color); }
.space-icon { margin-left: 10px; }

.aling-buttons{
  margin-top: 1.6%;
}
.opportunity-filter {
  border-radius: 12px;  
  display: flex;
}
.opportunity-filter .item {
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  font-size: small;
  text-transform: capitalize;
  width: 5%;
}
.badgesCompany {
  float: right;
}
.badgesCompany .pillItem {
  color: #353535;
  border: 1px solid #C0C0C0;
  border-radius: 15px;
  padding: 1px 5px 1px 5px;
  font-size: small;
  margin: 5px;
}

.badgesCompany .pillItem span {
  cursor: pointer;
}

.badgesCompany .pillItem span:hover {
  color: red !important;
}

.opportunity-filter .item:hover {
  background-color: #E9E9E9;;
}

.opportunity-filter .item a {
  color:black !important;
}

.datepicker .dropdown-menu {
  width: 220px;
}

.ddlValue .dropdown-menu {
  width: 180px;
}

@media (max-width: 1550px) {
  #table_investments table tbody td :first-child { min-width: 120px !important;  }
 #table_investors table tbody td:nth-child(2) { max-width: 50px; min-width: 50px; }
 #table_investors table tbody td:nth-child(3) { max-width: 150px; min-width: 150px; }
 #table_investors table tbody td:nth-child(4) { max-width: 150px; min-width: 150px; }
 #table_investors table tbody td:nth-child(5) { max-width: 150px; min-width: 150px; }
 #table_investors table tbody td:nth-child(6) { max-width: 150px; min-width: 150px; }
}

@media (max-width: 1000px) {
 .item {
  width: 25% !important;
 }
}

</style>
