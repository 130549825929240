<template>
    <div>
        <div style="display: flex;">
            <h1>Vendidos</h1> 
            <b-icon v-b-popover.hover.top="' Imóveis que você comprou cotas e que já tiveram a venda final realizada. Ou seja, investimento está totalmente completo.'" class="icon-title" icon="question-circle" />
        </div>
        <ListInvestmentUser id="listInvestmentUserSold"  :sold="true"></ListInvestmentUser>
    </div>
</template>
<script>

import ListInvestmentUser from './ListInvestmentUser.vue'
import { VBPopover, BImg } from 'bootstrap-vue'

export default {
    components: {
        ListInvestmentUser,
        BImg
    },
    directives: {
        'b-popover': VBPopover,
    },
    created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    },
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    },

    data(){
        return {
        }
    }
}
</script>
